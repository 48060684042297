import React from 'react';
import { PopoverLink } from '@poly/admin-book';
import { ProjectSupplierStatus } from '@poly/constants';
import { arrayOf, shape, string } from 'prop-types';
import { RemoveIcon, ThreeDotsPopover } from '@poly/admin-ui';

import { useRemoveProjectSupplier } from '../useRemoveProjectSupplier.js';
import { useUnassignProjectSupplier } from '../useUnassignProjectSupplier.js';

function RemoveSupplierButton({ project, _id }) {
  const onDelete = useRemoveProjectSupplier({
    supplierId: _id,
    project,
  });

  return <RemoveIcon onClick={onDelete} />;
}

RemoveSupplierButton.propTypes = {
  _id: string.isRequired,
  project: shape({
    estimates: arrayOf(shape({ supplier: shape({ _id: string }) })),
  }),
};

export function ProjectSupplierActionButtons({
  _id,
  project,
  statusInProject,
}) {
  const onUnassignSupplier = useUnassignProjectSupplier({
    project,
    supplierId: _id,
  });

  const onDeleteSupplier = useRemoveProjectSupplier({
    project,
    supplierId: _id,
    isRecurringProject: false,
  });

  return (
    <ThreeDotsPopover
      content={
        <>
          {statusInProject === ProjectSupplierStatus.assigned && (
            <PopoverLink onClick={onUnassignSupplier}>Unassign</PopoverLink>
          )}
          <PopoverLink onClick={onDeleteSupplier}>Delete</PopoverLink>
        </>
      }
    />
  );
}

ProjectSupplierActionButtons.propTypes = {
  _id: string,
  statusInProject: string,
  project: shape({ _id: string }),
};
