import { gql, useMutation } from '@apollo/client';
import { useModalContext, useNotificationState } from '@poly/admin-ui';

import { hasSupplierProjectEstimate } from './useRemoveProjectSupplier.js';

const UNASSIGN_PROJECT_SUPPLIER = gql`
  mutation UNASSIGN_PROJECT_SUPPLIER($input: UnassignSupplierAtProjectInput!) {
    unassignSupplierAtProject(input: $input) {
      project {
        _id
      }
    }
  }
`;

export const useUnassignProjectSupplier = ({ supplierId, project }) => {
  const { openConfirmModal } = useModalContext();
  const [unassignProjectSupplier] = useMutation(UNASSIGN_PROJECT_SUPPLIER);
  const { showSuccessNotification } = useNotificationState();

  const handleUnassignSupplier = async () => {
    await unassignProjectSupplier({
      variables: { input: { projectId: project?._id, supplierId } },
    });
    showSuccessNotification('Supplier was successfully unassigned');
  };

  return async () => {
    const isHasProjectEstimate = hasSupplierProjectEstimate(
      supplierId,
      project,
    );

    openConfirmModal({
      cancelBtnCaption: isHasProjectEstimate ? 'Cancel' : 'No',
      btnCaption: 'Yes',
      id: 'confirmUnassignSupplierModalId',
      content: isHasProjectEstimate
        ? 'If you unassign this supplier, the associated client estimate and rejected invoices will also be removed. Do you want to continue?'
        : 'Are you sure you want to unassign this supplier? All rejected invoices that associated with this supplier, will be removed from the WO card.',
      onConfirm: (closeConfirmModal) => async () => {
        await handleUnassignSupplier();
        closeConfirmModal();
      },
    });
  };
};
